import { defineStore } from "pinia";

export const useLoaderStore = defineStore("LoaderStore", {
  state: () => ({
    isLoading: false,
    timeoutId: null as NodeJS.Timeout | null,
  }),
  actions: {
    open(timeoutSeconds = 0): void {
      this.isLoading = true;
      if (timeoutSeconds > 0)
        this.timeoutId = setTimeout(() => this.close(), timeoutSeconds * 1000);
    },
    close(): void {
      this.isLoading = false;
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
  },
  getters: {
    status(): boolean {
      return this.isLoading;
    },
  },
});
