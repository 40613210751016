
import { defineComponent, ref, watch } from 'vue'
import { useLoaderStore } from '@/store/LoaderStore'
import { useAuthStore } from '@/store/AuthStore';
import useAlert from "@/composables/Alert"
import axios, { AxiosError } from 'axios';

export default defineComponent({
  name: "PrivacyTermsOfUseModal",
  setup() {
    /** Variables */
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const authStore = useAuthStore()
    const iAgree = ref(false)
    const isLoadingTerms = ref(false)
    const terms = ref("")
    const termsSuccess = ref(false)

    /** Methods */
    async function getCommitmentTermPainel(isOpen: boolean) {
      try {
        isLoadingTerms.value = isOpen
        const response = await axios.get("/api/getCommitmentTerm")
        const { data: { data: { description } } } = response
        terms.value = "Algo deu errado, não foi possível obter os termos de serviço e privacidade!"
        termsSuccess.value = false
        if(typeof description === "string" && description.length > 0) {
          terms.value = response.data.data.description
          termsSuccess.value = true
        }
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        isLoadingTerms.value = false
      }
    }

    async function postCommitmentTermPainel() {
      try {
        loaderStore.open()
        await axios.post("/api/postCommitmentTerm")
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        authStore.closeTermsModal()
        loaderStore.close()
      }
    }

    watch(() => authStore.termsModalStatus, async (isOpen) => isOpen && getCommitmentTermPainel(isOpen))

    return {
      authStore,
      postCommitmentTermPainel,
      iAgree,
      isLoadingTerms,
      terms,
      termsSuccess,
    }
  }
})
