
import { defineComponent, ref, watch } from 'vue';

interface IChronology {
  column: string;
  ascending: boolean;
}

interface ITableRow {
  [key: string]: any;
}

export interface ISortComponentCore {
  currentColumn: string;
  rows: ITableRow;
  chronology: IChronology[]
}

export interface IObjEmit {
  sortedList: ITableRow;
  sortComponentCore: ISortComponentCore;
}

export default defineComponent({
  name: "CampTableSortRowsByColumn",
  props: {
    column: {
      type: String,
      required: true
    },
    sortComponentCore: {
      type: Object as ()=> ISortComponentCore,
      required: true
    }
  },
  setup(props, { emit }) {
    // Variables
    const showUp = ref(true)
    const showDown = ref(true)
    const column = ref(props.column)
    const chronology = ref<IChronology[]>([])
    const countClick = ref(0)

    // Functions
    const handleResetColumn = () => {
      showUp.value = true
      showDown.value = true
      sortByColumn(column.value, true, true)
    }

    const resetColumn = () => {
      if(countClick.value > 2) {
        handleResetColumn()
        countClick.value = 0
      }
    }

    function sortByColumn(column: string, ascending: boolean, reset = false) {
      const list = props.sortComponentCore.rows.map((el: any) => ({ ...el }))
      chronology.value = props.sortComponentCore.chronology
      countClick.value += 1

      if(reset) {
        chronology.value = chronology.value.filter(el => el.column !== column)
      } else {
        showUp.value = false
        showDown.value = false
  
        if(ascending) 
          showUp.value = true
        else
          showDown.value = true
  
        const available = !(chronology.value.map(el => el.column).includes(column))
        if(available)
          chronology.value.push({ column, ascending })

        const index = chronology.value.findIndex(el => el.column === column)
        if(index >= 0)
          chronology.value[index] = { column, ascending }
      }

      chronology.value.forEach(el => {
        list.sort((a:any, b:any) => {
          const propA = a[el.column];
          const propB = b[el.column];
  
          let comparison = 0;
  
          if (propA > propB) {
            comparison = 1;
          } else if (propA < propB) {
            comparison = -1;
          }
  
          return el.ascending ? comparison : comparison * -1;
        })
      })

      emit('update:rowsSortedByColumn', <IObjEmit>{
        sortedList: list,
        sortComponentCore: {
          currentColumn: column,
          rows: props.sortComponentCore.rows.map((el: any) => ({ ...el })),
          chronology: chronology.value.map(el => ({ ...el }))
        }
      });
    }

    // Life Cycles
    watch(() => countClick.value, resetColumn)

    return {
      column,
      resetColumn,
      sortByColumn,
      showUp,
      showDown
    }
  }
})
