
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        toggle: {
            type: Boolean,
            default: false
        },
    },
})
