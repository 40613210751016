
import { defineComponent } from 'vue'
import { generateExcel } from '@/services/GenerateExcelService'

export default defineComponent({
  name: "CampBtnGenerateExcel",
  props: {
    label: {
      type: String,
      default: "Baixar planilha"
    },
    btnClass: {
      type: String,
      default: "btn_background_1"
    },
    tableData: {
      type: Array as () => Record<string, any>[] | null,
      required: true
    },
    fileName: {
      type: String,
      default: "planilha"
    },
    hiddenColumnList: {
      type: Array as () => string[] | [],
      default: []
    },
    renamedColumnList: {
      type: Array as () => string[] | [],
      default: []
    },
    textColumnList: {
      type: Array as () => string[] | [],
      default: []
    },
  },
  setup() {
    return {
      generateExcel
    };
  }
})
