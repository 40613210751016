import ExcelJS from 'exceljs';

// export async function generateExcel(
//     tableData: Record<string, any>[],
//     fileName = 'planilha',
//     hiddenColumnList: string[] = [],
//     renamedColumnList: string[] = [],
// ) {
//     const workbook = new ExcelJS.Workbook();
//     const worksheet = workbook.addWorksheet(fileName);

//     // Remover colunas
//     hiddenColumnList.forEach(col => {
//         tableData = tableData.filter(row => {
//             if(Object.keys(row).includes(col)) {
//                 delete row[col]
//                 return row
//             }
//             return row
//         })
//     })

//     // Renomear colunas
//     const alias: {
//         cols1: string[],
//         cols2: string[],
//         aux: string[],
//     } = {
//         cols1: [],
//         cols2: [],
//         aux: [],
//     }

//     renamedColumnList.forEach(col => {
//         alias.aux = col.split(' as ')
//         alias.cols1.push(alias.aux[0])
//         alias.cols2.push(alias.aux[1])
//     })

//     // Adicione os cabeçalhos da tabela ao arquivo Excel
//     const headers = Object.keys(tableData[0]).map(key => {
//         if(alias.cols1.includes(key))
//             return alias.cols2[alias.cols1.indexOf(key)];
//         return key
//     })
//     const headersTranslate = headers.map(header => header);
//     worksheet.addRow(headersTranslate);

//     // Adicione os dados da tabela ao arquivo Excel
//     tableData.forEach(row => {
//         worksheet.addRow(Object.values(row));
//     });

//     // Gere o arquivo Excel e faça o download
//     const buffer = await workbook.xlsx.writeBuffer();
//     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = `${fileName}.xlsx`;
//     link.click();
// }

// export async function parseExcel(file) {
//     const workbook = new ExcelJS.Workbook();
//     await workbook.xlsx.load(file);

//     const worksheet = workbook.worksheets[0]; // assumindo que estamos lendo da primeira planilha

//     const headers = worksheet.getRow(1).values; // assume que a primeira linha contém os cabeçalhos

//     const data: Record<string, any>[] = [];
//     worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
//         if (rowNumber === 1) return; // pulando a primeira linha dos cabeçalhos
//         const rowData = {};
//         row.eachCell((cell, colNumber) => {
//             const header = headers[colNumber];
//             rowData[header] = cell.value;
//         });
//         data.push(rowData);
//     });

//     return data;
// }

export async function generateExcel(
    tableData: Record<string, any>[],
    fileName = 'planilha',
    hiddenColumnList: string[] = [],
    renamedColumnList: string[] = [],
    textColumnsList: string[] = [] // Adicione este parâmetro para especificar colunas como texto
) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName);
 
    // Remover colunas
    hiddenColumnList.forEach(col => {
        tableData = tableData.filter(row => {
            if (Object.keys(row).includes(col)) {
                delete row[col];
                return row;
            }
            return row;
        });
    });
 
    // Renomear colunas
    const alias: {
        cols1: string[],
        cols2: string[],
        aux: string[],
    } = {
        cols1: [],
        cols2: [],
        aux: [],
    };
 
    renamedColumnList.forEach(col => {
        alias.aux = col.split(' as ');
        alias.cols1.push(alias.aux[0]);
        alias.cols2.push(alias.aux[1]);
    });
 
    // Adicione os cabeçalhos da tabela ao arquivo Excel
    const headers = Object.keys(tableData[0]).map(key => {
        if (alias.cols1.includes(key)) {
            return alias.cols2[alias.cols1.indexOf(key)];
        }
        return key;
    });
    const headersTranslate = headers.map(header => header);
    worksheet.addRow(headersTranslate);
 
    // Adicione os dados da tabela ao arquivo Excel
    tableData.forEach((row, rowIndex) => {
        const rowValues = Object.values(row);
        const excelRow = worksheet.addRow(rowValues);
 
        // Aplicar o formato de texto para colunas específicas
        rowValues.forEach((value, colIndex) => {
            const columnName = headers[colIndex];
            if (textColumnsList.includes(columnName)) {
                excelRow.getCell(colIndex + 1).numFmt = '@';
            }
        });
    });
 
    // Gere o arquivo Excel e faça o download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
 
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
}
 
export async function parseExcel(file) {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);
 
    const worksheet = workbook.worksheets[0]; // assumindo que estamos lendo da primeira planilha
 
    const headers = worksheet.getRow(1).values; // assume que a primeira linha contém os cabeçalhos
 
    const data: Record<string, any>[] = [];
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 1) return; // pulando a primeira linha dos cabeçalhos
        const rowData = {};
        row.eachCell((cell, colNumber) => {
            const header = headers[colNumber];
            rowData[header] = cell.value;
        });
        data.push(rowData);
    });
 
    return data;
}