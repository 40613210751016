
import { computed, CSSProperties } from 'vue';

interface MyComponentProps {
  gapTop?: number;
}

export default {
  props: {
    gapTop: {
      type: Number,
      default: 80
    }
  },
  setup(props: MyComponentProps) {
    const containerStyle = computed(() => ({
      position: 'relative',
      right: '-0.5rem',
      padding: 0,
      margin: 0,
      marginBottom: `-${props.gapTop}px`
    } as CSSProperties));

    return {
      containerStyle
    };
  }
};
