import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31ff7455"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor-base-button-icon-c24be034-8dc0-436f-af8e-a271945d941b w-auto btn btn-sm px-1 mx-1" }
const _hoisted_2 = {
  key: 0,
  class: "editor-base-button-icon--on"
}
const _hoisted_3 = {
  key: 1,
  class: "editor-base-button-icon--off"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    (_ctx.toggle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "on", {}, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "off", {}, undefined, true)
        ]))
  ]))
}