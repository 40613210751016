
import { defineComponent, onMounted } from "vue";
import { useSocketStore } from "./store/SocketStore";


//import '@/assets/sass/distribuidor/atria.scss';
export default defineComponent({
  name: "app",

  setup() {
    // const loaderStore = useLoaderStore();
    const socketStore = useSocketStore()

    onMounted(() => {
      socketStore.setPusher()
      socketStore.setEcho()
    })
  },
});
