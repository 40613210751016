import { defineStore } from "pinia";

interface IFilterMainStore {
  activeModal: boolean;
  pathName: string;
}

export const useFilterMainStore = defineStore("FilterMainStore", {
  state: (): IFilterMainStore => {
    return {
      activeModal: false,
      pathName: "",
    };
  },
  actions: {
    showModal() {
      this.activeModal = true;
    },
    hideModal() {
      this.activeModal = false;
    },
    setPathName(pathName: string) {
      this.pathName = pathName
    },
  },
  getters: {
    isModalActive(): boolean {
      return this.activeModal;
    },
    getPathName(): string {
      return this.pathName
    }
  },
});
