
import { defineComponent } from "vue"
import { Editor } from "@tiptap/vue-3"
import { BaseButtonIcons } from "."

export default defineComponent({
    name: "UnderlineButton",
    components: {
        BaseButtonIcons
    },
    props: {
        editor: {
            type: Object as () => Editor,
            required: true
        }
    },
    setup() { }
})
