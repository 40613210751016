import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "camp-tiptap-editor rounded"
}
const _hoisted_2 = { class: "camp-tiptap-editor-header row g-2 px-2 py-1 d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComboText = _resolveComponent("ComboText")!
  const _component_BoldButton = _resolveComponent("BoldButton")!
  const _component_ItalicButton = _resolveComponent("ItalicButton")!
  const _component_UnderlineButton = _resolveComponent("UnderlineButton")!
  const _component_StrikeButton = _resolveComponent("StrikeButton")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_UndoButton = _resolveComponent("UndoButton")!
  const _component_RedoButton = _resolveComponent("RedoButton")!
  const _component_EditorContent = _resolveComponent("EditorContent")!

  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ComboText, {
            "hidden-starter-kit-items": _ctx.hiddenStarterKitItems,
            editor: _ctx.editor
          }, null, 8, ["hidden-starter-kit-items", "editor"]),
          (!(_ctx.hiddenStarterKitItems.find(el => el === 'bold')))
            ? (_openBlock(), _createBlock(_component_BoldButton, {
                key: 0,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true),
          (!(_ctx.hiddenStarterKitItems.find(el => el === 'italic')))
            ? (_openBlock(), _createBlock(_component_ItalicButton, {
                key: 1,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true),
          (_ctx.editor.options.extensions.find(el => el.name === 'underline'))
            ? (_openBlock(), _createBlock(_component_UnderlineButton, {
                key: 2,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true),
          (!(_ctx.hiddenStarterKitItems.find(el => el === 'strike')))
            ? (_openBlock(), _createBlock(_component_StrikeButton, {
                key: 3,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true),
          (_ctx.editor.options.extensions.find(el => el.name === 'link'))
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 4,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true),
          (!(_ctx.hiddenStarterKitItems.find(el => el === 'undo')))
            ? (_openBlock(), _createBlock(_component_UndoButton, {
                key: 5,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true),
          (!(_ctx.hiddenStarterKitItems.find(el => el === 'redo')))
            ? (_openBlock(), _createBlock(_component_RedoButton, {
                key: 6,
                editor: _ctx.editor
              }, null, 8, ["editor"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_EditorContent, {
          editor: _ctx.editor,
          class: "camp-tiptap-editor-body"
        }, null, 8, ["editor"])
      ]))
    : _createCommentVNode("", true)
}