
import { defineComponent } from 'vue'

export default defineComponent({
  name: "CampConstrainProportionDiv",
  props: {
    height: {
      type: String,
      default: "100"
    }
  },
  setup({height}) {
    return {
      height,
    };
  }
})
