<template>
  <header class="row mb-7 d-flex align-items-center">
    <div class="col-sm-6 py-2">
      <h2 class="cor_texto_1"><slot name="title"></slot></h2>
    </div>
    <div class="col-sm-6 d-flex flex-row-reverse">
      <slot name="button"></slot>
    </div>
  </header>
</template>

<style scoped>
  h2 {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
</style>
