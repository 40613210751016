import { IStoreData } from "@/models/StoreModel";
import axios from "axios";
import { defineStore } from "pinia";

interface IStoreStore {
  id: number | null;
  fantasyName: string;
  store: IStoreData | null
}

export const useStoreStore = defineStore("StoreStore", {
  state: (): IStoreStore => {
    return {
      id: null,
      fantasyName: "",
      store: null
    };
  },
  actions: {
    async setId(id: number | null) {
      if(id != null ) {
        this.id = id;
        try {
          const response = await axios.post('/api/setStoreSession', {id: id})
          this.store = response.data.data
        } catch (error) {
          
        }
      }
    },
    setFantasyName(name: string) {
      this.fantasyName = name
    }
    ,
    setStore(store) {
        this.store = store
        this.fantasyName = store.fantasy_name
        this.id = store.id
    },
    setStoreV2(store: IStoreData | null) {
      this.store = store
    }
  },
  getters: {
    getId(): number | null {
      return this.id;
    },
    getStore(): IStoreData | null {
      return this.store;
    },
    getFantasyName(): string {
      return this.fantasyName
    }
  },
});
