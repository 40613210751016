import axios from "axios";
import { defineStore } from "pinia";
import { ICompanyData } from "./CompaniesStore";
import { IStoreData } from "@/models/StoreModel";
import { useStoreStore } from "./StoreStore";

interface ICompanyStore {
  id: number | null;
  fantasyName: string;
  company:  ICompanyData | null,
  store: IStoreData | null
  updateFilter: boolean
}

export const useCompanyStore = defineStore("CompanyStore", {
  state: (): ICompanyStore => {
    return {
      id: null,
      fantasyName: "",
      company: null as any,
      store: null as any,
      updateFilter: false
    };
  },
  actions: {
    async setId(id: number | null) {
      if(id != null) {
        // this.id = 0
        try {
          const response = await axios.post('/api/setCompanySession', {id: id})
          this.id = response.data.data.id
          this.company = response.data.data
          this.fantasyName = response.data.data.fantasy_name
          return true
        } catch (error) {
          return false
        }
      }
    },
    async setCompanyAndStore(idCompany: number | null, idStore: number | null) {
      try {
          const storeStore = useStoreStore()
          const response = await axios.post('/api/setCompanySession', {id: idCompany})
          this.id = response.data.data.id
          this.company = response.data.data
          this.fantasyName = response.data.data.fantasy_name
          const responseStore = await axios.post('/api/setStoreSession', {id: idStore})
          storeStore.id = responseStore?.data?.data?.id || 0
          this.updateFilter = !this.updateFilter
          storeStore.store = responseStore?.data?.data
          storeStore.setFantasyName(responseStore?.data?.data?.fantasy_name)
        } catch (error) {
          return this.updateFilter = false
        }
    },
    setCompany(company) {
      // this.id = company.id
      this.fantasyName = company.fantasyName
      this.company = company
    },
    setCompanyV2(company: ICompanyData | null) {
      this.company = company
    },
    setFantasyName(company) {
      this.company = company
      
    }
  },
  getters: {
    getId(): number | null {
      if(this.id == null) {
        axios.post('/api/getCompanySession').then(response => {
          // console.log(response.data.data)
          return this.id = response?.data?.data?.id
        })
      }
      return this.id;
    },
    async getAsyncId(): Promise<number | null> {
      if(this.id == null) {
        const response = await axios.post('/api/getCompanySession')
        console.log(response.data.data.id)
        return this.id = response.data.data.id
      }else {
        return this.id;
      }
    },
    getCompany(): any | null {
      return this.company;
    },
    getFantasyName(): string {
      return this.fantasyName
    }
  },
});
