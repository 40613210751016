import axios, { AxiosError } from "axios";
import { useLoaderStore } from "@/store/LoaderStore";

axios.defaults.baseURL= process.env.VUE_APP_URL_API
axios.defaults.withCredentials = true

axios.interceptors.response.use(
  response => response,
  error => {
    const LoaderStore = useLoaderStore()
    const path = window.location.pathname;
    if (error && error.response && error.response.status === 401 && path !== "/login") {
      window.localStorage.clear();
      window.location.replace('/login');
    }
    LoaderStore.close()
    throw error
  }
);

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response) {
//       // Erro de resposta HTTP com um código de status
//       // console.log(error.response); // Mensagem de erro do backend
//       // console.log(error.response.status); // Código de status HTTP
//       return Promise.reject(error.response);
//     } else if (error.request) {
//       // Requisição foi feita, mas não recebeu resposta
//       // console.log(error.request);
//       return Promise.reject(error.request);
//     } else {
//       // Outro tipo de erro
//       // console.log('Erro:', error.message);
//       return Promise.reject(error);
//     }
//   }
// );
