import { defineStore } from "pinia";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

interface ISocketStore {
    echo: null | Echo,
    pusher: null | Pusher
}

export const useSocketStore = defineStore("SocketStore", {
    state: ():ISocketStore => {
        return {
            echo: null,
            pusher: null
        };
    },
    actions: {
        setPusher() {
            this.pusher = new Pusher('', { cluster: "sa1" })
        },
        setEcho() {
            this.echo = new Echo({
                broadcaster: "pusher",
                cluster: "sa1",
                key: 'e48da917909bc0e586cb',
                secret: "fac51e64717e7a0390a4",
                appId: '1689397',
                forceTLS: false,
                // authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
                // auth: {
                //     headers: {
                //         Authorization: localStorage.getItem('token')
                //     },
                // },
            })
        },
    }
})
