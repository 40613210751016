// import AuthService from "@/services/AuthService";
import { createRouter, createWebHistory, RouteRecordRaw, useRoute } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
// import Gard from "@/services/Middleware";
import axios from "axios";
import { useAuthStore } from "@/store/AuthStore";
import { useFilterMainStore } from "@/store/FilterMainStore";
import { useCompanyStore } from "@/store/CompanyStore";
import { useStoreStore } from "@/store/StoreStore";
import { useLoaderStore } from "@/store/LoaderStore";

const routes: Array<RouteRecordRaw> = [
  { 
    path: "/",
    redirect: "/login",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        // meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Login.vue")
      },
      {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/Logout.vue"),
      },
      {
        path: "/companhias",
        name: "companyList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Companies/ListPage.vue"),
          filter: () => import("@/views/Companies/Components/FilterMainRack.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company"],
            requiredFields: [],
            allCompany: false
          }
        }
      },
      {
        path: "/companhia/cadastrar",
        name: "companyPost",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/Companies/PostPage.vue")
      },
      {
        path: "/companhia/atualizar/:id",
        name: "companyPut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/Companies/PutPage.vue")
      },
      {
        path: "/lojas",
        name: "storeList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/Stores/ListPage.vue"),
          filter: () => import("@/views/Stores/Components/FilterMainRack.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company"],
            requiredFields: [],
            allCompany: true,
            allStore: false
          }
        }
      },
      {
        path: "/loja/cadastrar",
        name: "StorePost",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Stores/PostPage.vue")
      },
      {
        path: "/loja/atualizar/:id",
        name: "StorePut",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Stores/PutPage.vue")
      },
      {
        path: "/perfil",
        name: "profilePut",
        // meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Profile/PutPage.vue")
      },
      {
        path: "/usuarios",
        name: "userList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/Users/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/Users/FilterUsersListPage.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company","store"],
            requiredFields: [],
            routeNameToRedirect: "storeList",
            goToBackBtnDesc: "Ir para Lojas",
            allCompany: false,
            allStore: true
          }
        }
      },
      {
        path: "/usuario/cadastrar",
        name: "userPost",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Users/PostPage.vue")
      },
      {
        path: "/usuario/atualizar/:id",
        name: "userPut",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Users/PutPage.vue")
      },
      {
        path: "/produtos",
        name: "productList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/Products/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/Products/FilterProductsListPage.vue")
        }, 
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/produto/cadastrar",
        name: "productPost",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Products/PostPage.vue")
      },
      {
        path: "/produto/atualizar/:id",
        name: "productPut",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Products/PutPage.vue")
      },
      {
        path: "/produtos-fracionados",
        name: "FractionsList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Fractions/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/Fractions/FilterFractionsListPage.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/produto-fracionado/cadastrar",
        name: "FractionPost",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Fractions/PostPage.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/produto-fracionado/atualizar/:id",
        name: "FractionPut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/Fractions/PutPage.vue")
      },
      {
        path: "/categorias",
        name: "categoryList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Categories/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/Categories/FilterCategoriesListPage.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/categoria/cadastrar",
        name: "categoryPost",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/Categories/PostPage.vue")
      },
      {
        path: "/categoria/atualizar/:id",
        name: "categoryPut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/Categories/PutPage.vue")
      },
      {
        path: "/perfis-das-lojas",
        name: "StoreProfilesList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/StoreProfiles/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/StoreProfiles/FilterStoreProfilesListPage.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/perfis-das-lojas/atualizar/:id",
        name: "StoreProfilePut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/StoreProfiles/PutPage.vue")
      },
      {
        path: "/perfis-das-lojas/cadastrar",
        name: "StoreProfilePost",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/StoreProfiles/PostPage.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/vendedores",
        name: "competitorList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/Competitors/ListPage.vue"),
          // filter: () => import("@/views/Competitors/Components/FilterMainRack.vue"),
          filter: () => import('@/layout/header/partials/filters/Competitors/FilterCompetitorsListPage.vue')

        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["store"],
            requiredFields: [],
            allStore: true
          }
        }
      },
      {
        path: "/vendedor/cadastrar",
        name: "competitorPost",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Competitors/PostPage.vue")
      },
      {
        path: "/vendedor/atualizar/:id",
        name: "competitorPut",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Competitors/PutPage.vue")
      },
      {
        path: "/consultores",
        name: "consultantList",
        meta: {nivelCargo: [100, 200, 300, 400, 500, 550]},
        components: {
          default: () => import("@/views/Consultants/ListPage.vue"),
          filter: () => import("@/views/Consultants/Components/FilterMainRack.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["store"],
            requiredFields: [],
            allStore: true
          }
        }
      },
      {
        path: "/consultor/cadastrar",
        name: "consultantPost",
        meta: {nivelCargo: [100, 200, 300, 400, 500, 550]},
        component: () => import("@/views/Consultants/PostPage.vue")
      },
      {
        path: "/consultor/atualizar/:id",
        name: "consultantPut",
        meta : {nivelCargo : [100, 200, 300, 400, 500, 550]},
        component: () => import("@/views/Consultants/PutPage.vue")
      },
      {
        path: "/lojas/produtos",
        name: "storeProductPut",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/ProductStore/ListPage.vue"),
          // filter: () => import("@/views/ProductStore/Components/FilterMainRack.vue")/** Before */,
          filter: () => import("@/layout/header/partials/filters/ProductStore/FilterProductStoreListPage.vue")/** After */,
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["store"],
            requiredFields: ["store"],
            routeNameToRedirect: "storeList",
            goToBackBtnDesc: "Ir para Lojas"
          }
        }
      },
      {
        path: "/lojas/produtos/adicionar",
        name: "storeProductPost",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/ProductStore/PutPage.vue")
      },
      {
        path: "/estoque",
        name: "stockList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/Stock/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/Stock/FilterStockListPage.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company", "store"],
            allCompany: false,
            allStore: true,
          }
        }
      },
      {
        path: "/estoque/expirado",
        name: "stockExpireList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/StockExpire/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/StockExpire/FilterStockExpireListPage.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company", "store"],
            allCompany: false,
            allStore: true,
          }
        }
      },
      {
        path: "/estoque/adicionar",
        name: "stockPost",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        component: () => import("@/views/Stock/PostPage.vue")
      },
      {
        path: "/remessas",
        name: "remittanceList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Remittance/ListPage.vue"),
          filter: () => import("@/views/Remittance/Components/Remittance/FilterMainRack.vue")
        },
        props: {
          filter: {
            subtitle: "Filtrar Solicitações",
            fields: ["company", "remittance"],
            requiredFields: ["company"],
            routeNameToRedirect: "companyList",
            goToBackBtnDesc: "Ir para Companhias",
            allCompany: false
          }
        }
      },
      {
        path: "/remessas/historico",
        name: "remittanceHistoryList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Remittance/HistoryPage.vue"),
          filter: () => import("@/views/Remittance/Components/History/FilterMainRack.vue")
        },
        props: {
          filter: {
            subtitle: "Filtrar Histórico de Remessas",
            fields: ["company", "remittance"],
            requiredFields: ["company"],
            routeNameToRedirect: "companyList",
            goToBackBtnDesc: "Ir para Companhias"
          }
        }
      },
      {
        path: "/remessas/premio",
        name: "RemittanceRewardList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Remittance/RewardPage.vue"),
          filter: () => import("@/views/Remittance/Components/Reward/FilterMainRack.vue")
        },
        props: {
          filter: {
            subtitle: "Filtrar Histórico de Prêmios",
            fields: ["company"],
            requiredFields: ["company"],
            routeNameToRedirect: "companyList",
            goToBackBtnDesc: "Ir para Companhias"
          }
        }
      },
      {
        path: "/vendas",
        name: "saleList",
        meta : {nivelCargo : [100, 200, 300, 400, 500, 600]},
        components: {
          default: () => import("@/views/Sales/ListPage.vue"),
          filter: () => import("@/views/Sales/Components/FilterMainRack.vue")
        },
        props: {
          filter: {
            subtitle: "Filtrar Vendas por Lojas",
            fields: ["sale", "store"],
            allStore: false
          }
        }
      },
      {
        path: "/social",
        name: "socialList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Social/ListPage.vue"),
          filter: () => import("@/views/Social/Components/FilterMainRack.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro de Postagens do Social",
            fields: ["post"],
            requiredFields: []
          }
        }
      },
      {
        path: "/treinamentos",
        name: "trainingList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Training/ListPage.vue"),
          filter: () => import("@/views/Training/Components/FilterMainRackList.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro para Treinamentos",
            fields: ["company"],
            allCompany: false,
            allStore: false,
            requiredFields: ["company"],
            routeNameToRedirect: "companyList",
            goToBackBtnDesc: "Ir para Companhias"
          }
        }
      },
      {
        path: "/treinamento/cadastrar",
        name: "trainingPost",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Training/PostPage.vue"),
          filter: () => import("@/views/Training/Components/FilterMainRackPost.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro para Treinamentos",
            fields: ["company"],
            allCompany: false,
            requiredFields: ["company"],
            routeNameToRedirect: "trainingList",
            goToBackBtnDesc: "Ir para Treinamentos"
          }
        }
      },
      {
        path: "/treinamento/atualizar/:id",
        name: "trainingPut",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Training/PutPage.vue"),
          filter: () => import("@/views/Training/Components/FilterMainRackPut.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro para Treinamentos",
            fields: ["company"],
            allCompany: false,
            requiredFields: ["company"],
            routeNameToRedirect: "trainingList",
            goToBackBtnDesc: "Ir para Treinamentos"
          }
        }
      },
      {
        path: "/relatorio/resgates",
        name: "reportRescue",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Reports/Components/ReportRemittance.vue")
        },
      },
      {
        path: "/relatorio/vendas",
        name: "reportSales",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Reports/Components/ReportSales.vue")
        },
      },
      {
        path: "/relatorio/notas-fiscais",
        name: "reportInvoices",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Reports/Components/ReportInvoices.vue")
        },
      },
      {
        path: "/relatorio/notificacoes",
        name: "reportPushNotifications",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Reports/Components/ReportPushNotifications/ReportPushNotifications.vue"),
          // filter: () => import("@/layout/header/partials/filters/Reports/FilterReportPushNotifications/FilterReportPushNotifications.vue")
        },
      },
      {
        path: "/Home",
        name: "home",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Home/Home.vue"),
          filter: () => import("@/layout/header/partials/filters/Home/FilterHomeView.vue")
        },
      },
      {
        path: "/logs/estoque",
        name: "InvoicesLogList",
        meta : {nivelCargo : [100]},
        component: () => import("@/views/Developer/InvoicesLogList.vue")
      },
      {
        path: "/conquistas-auxiliares",
        name: "AchievementList",
        meta : {nivelCargo : [100]},
        components: {
          default: () => import("@/views/Achievements/ListPage.vue"),
          filter: () => import("@/views/Achievements/Components/FilterMainRackViewAchievementList.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/conquista-auxiliar/atualizar/:id",
        name: "AchievementPut",
        meta : {nivelCargo : [100]},
        component: () => import("@/views/Achievements/PutPage.vue")
      },
      {
        path: "/logs/remessas",
        name: "RemittancesLogList",
        meta : {nivelCargo : [100]},
        component: () => import("@/views/Developer/RemittancesLogList.vue")
      },
      {
        path: "/logs/push-notification",
        name: "PushNotificationLogList",
        meta : {nivelCargo : [100]},
        component: () => import("@/views/Developer/PushNotificationLogList.vue")
      },
      {
        path: "/lista/vendedores",
        name: "competitorsLogList",
        meta : {nivelCargo : [100]},
        component: () => import("@/views/Developer/CompetitorsLogList.vue")
      },
      {
        path: "/notificacoes-automaticas",
        name: "NotificationsList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Notifications/ListPage.vue"),
          filter: () => import("@/views/Notifications/Components/FilterMainRackList.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro para Notificacoes",
            fields: ["company"],
            allCompany: false,
            allStore: false,
            requiredFields: [],
            routeNameToRedirect: "companyList",
            goToBackBtnDesc: "Ir para Companhias",
          }
        }
      },
      {
        path: "/notificacoes-personalizadas",
        name: "CustomNotifications",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/CustomNotifications/CustomNotifications.vue"),
        },
      },
      {
        path: "/conquistas",
        name: "AchievementListByCompany",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Achievements/ListByCompanyPage.vue"),
          filter: () => import("@/views/Achievements/Components/FilterMainRackViewAchievementListByCompany.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/selecionarcompanhia",
        name: "FilterCompanySession",
        meta : {nivelCargo : [100, 200, 300, 400, 500, 600]},
        components: {
          default: () => import("@/views/CompanySession/CompanySession.vue"),
          filter: () => import("@/layout/header/partials/filters/CompanySession/FilterCompanySession.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Companhia",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/grupos",
        name: "GroupList",
        meta : {nivelCargo : [100, 200, 300, 400, 500]},
        components: {
          default: () => import("@/views/Groups/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/Groups/FilterGroupsListPage.vue")
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        }
      },
      {
        path: "/grupos/atualizar/:id",
        name: "GroupPut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/Groups/PutPage.vue")
      },
      {
        path: "/grupos/cadastrar",
        name: "GroupPost",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/Groups/PostPage.vue"),
        },
      },
      {
        path: "/gestao-das-lojas",
        name: "RegionalManagementList",
        meta : {nivelCargo : [100, 200, 300, 400, 500, 550]},
        components: {
          default: () => import("@/views/RegionalManagement/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/RegionalManagement/FilterRegionalManagementListPage.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        } 
      },
      {
        path: "/gestao-das-lojas/cadastrar",
        name: "RegionalManagementPost",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/RegionalManagement/PostPage.vue")
      },
      {
        path: "/gestao-das-lojas/atualizar/:id",
        name: "RegionalManagementPut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/RegionalManagement/PutPage.vue")
      },
      {
        path: "/catalogo-de-premios",
        name: "AwardsCatalogList",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/AwardsCatalog/ListPage.vue"),
          filter: () => import("@/layout/header/partials/filters/AwardsCatalog/FilterAwardsCatalogListPage.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        } 
      },
      {
        path: "/catalogo-de-premios/cadastrar",
        name: "AwardsCatalogPost",
        meta : {nivelCargo : [100, 200]},
        components: {
          default: () => import("@/views/AwardsCatalog/PostPage.vue"),
        },
        props: {
          filter: {
            subtitle: "Filtro Geral",
            fields: ["company"],
            requiredFields: ["company"],
            allCompany: false
          }
        } 
      },
      {
        path: "/catalogo-de-premios/atualizar/:id",
        name: "AwardsCatalogPut",
        meta : {nivelCargo : [100, 200]},
        component: () => import("@/views/AwardsCatalog/PutPage.vue")
      },
    ]
  },
  {
    path: "/",
    meta : {nivelCargo : [100, 200, 300, 400, 500]},
    component: () => import("@/layout/Login.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue")
      }
    ]
  },
  {
    // the 401 route, when none of the above matches
    path: "/401",
    name: "401",
    component: () => import("@/views/Error401.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  useFilterMainStore().setPathName(to.path)
  try {
    useLoaderStore().open()
    const user = await useAuthStore().getUserAuth()
    if(useAuthStore().isLoggedIn && !(user.data?.data?.hasAcceptedTerm)) {
      useAuthStore().openTermsModal()
    } else {
      useAuthStore().closeTermsModal()
    }
    if(useAuthStore().getCompany != null) {
      await useCompanyStore().setCompany(useAuthStore().getCompany)
    }
    if(useAuthStore().getCompany == null && to.name != 'FilterCompanySession' && to.name != 'logout') {
      return { name: 'FilterCompanySession' }
    }
    if(useAuthStore().getStore != null) {
      await useStoreStore().setStore(useAuthStore().getStore)
    }
    if (!useAuthStore().isLoggedIn && to.name !== 'login') { /** redirect the user to the login page */
      return { name: 'login' }
    }  
    if(useAuthStore().isLoggedIn && useAuthStore().user.user.role.level < 600 && to.name === 'login') {
      return { name: 'storeList' }
    }
    if(useAuthStore().isLoggedIn && useAuthStore().user.user.role.level >= 600 && to.name === 'login') {
      return { name: 'saleList' }
    }
    if(Array.isArray(to.meta.nivelCargo)) {
      if(!to.meta.nivelCargo?.includes(useAuthStore().user.user.role.level)) {
        return { name: '404' }
      }
    }
  } catch (error) {
    if (!useAuthStore().isLoggedIn && to.name !== 'login') { /** redirect the user to the login page (when the backend is unavailable) */
      return { name: 'login' }
    } 
  } finally {
    if(!(to.name === 'logout'))
      useLoaderStore().close()
  }
})

// router.afterEach((to, from) => {
//   document.body.classList.remove("page-loading");
// });

export default router;
