
import { defineComponent, onMounted, ref, watch } from 'vue';
import { CampDropdown, CampDropdownHeader, CampDropdownItem } from '.';

interface IButton {
    id: number,
    activated: boolean,
    show: boolean
}

export default defineComponent({
    name: "CampFooterPaginationControl",
    components: {
        CampDropdown,
        CampDropdownHeader,
        CampDropdownItem,
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },
    setup(props, { emit }) {
        const currentIndex = ref(props.index)
        const newIndex = ref(props.index)
        const maxButton = ref(7)
        const buttonListSetup1= ref<IButton[] >(new Array())
        const buttonListSetup2= ref<IButton[] >(new Array())

        function initButtonListSetup1() {
            if(currentIndex.value > props.total)
                currentIndex.value = 1

            buttonListSetup1.value = new Array();

            for(let i = 0; i < props.total; i++) {
                if((i + 1) === currentIndex.value)
                    buttonListSetup1.value.push({ id: (i + 1), activated: true, show: true })
                else
                    buttonListSetup1.value.push({ id: (i + 1), activated: false, show: true })
            }
        }

        function initButtonListSetup2() {
            if(currentIndex.value > props.total)
                currentIndex.value = 1

            buttonListSetup2.value = new Array();

            for(let i = 0; i < props.total; i++) {
                if((i + 1) === currentIndex.value)
                    buttonListSetup2.value.push({ id: (i + 1), activated: true, show: false })
                else
                    buttonListSetup2.value.push({ id: (i + 1), activated: false, show: false })
            }

            handleButtonListSetup2()
        }

        function handleButtonListSetup2() {
            if(currentIndex.value < (props.total - 4)) {
                buttonListSetup2.value = buttonListSetup2.value.map((el, id) => {
                    if(el.id >= currentIndex.value - 1 && el.id < currentIndex.value + 4)
                        return { ...el, show: true }
                    else
                        return { ...el, show: false }
                })
            } else {
                buttonListSetup2.value = buttonListSetup2.value.map(el => ({ ...el, show: false }))
                buttonListSetup2.value[props.total - 6].show = true
                buttonListSetup2.value[props.total - 5].show = true
                buttonListSetup2.value[props.total - 4].show = true
                buttonListSetup2.value[props.total - 3].show = true
                buttonListSetup2.value[props.total - 2].show = true
            }
        }

        function handleActiveButon(id: number) {
            currentIndex.value = +id
            newIndex.value = currentIndex.value
            if(props.total <= maxButton.value) {
                buttonListSetup1.value = buttonListSetup1.value.map(el => ((el.id === currentIndex.value) ? { ...el, activated: true } : { ...el, activated: false }))
            }
            else {
                handleButtonListSetup2()
                buttonListSetup2.value = buttonListSetup2.value.map(el => ((el.id === currentIndex.value) ? { ...el, activated: true } : { ...el, activated: false }))
            }
        }

        function hanldeSearchPage() {
            newIndex.value = +newIndex.value
            newIndex.value = (newIndex.value > 0 && newIndex.value <= props.total) ? newIndex.value : currentIndex.value
            handleActiveButon(newIndex.value)
        }

        watch(() => currentIndex.value, () => emit("get:UpdatedPageID", currentIndex.value))

        onMounted(() => (props.total <= maxButton.value) ? initButtonListSetup1() : (initButtonListSetup2()))

        watch(() => props.total, () => (props.total <= maxButton.value) ? initButtonListSetup1() : (initButtonListSetup2()), { deep: true })

        return {
            currentIndex,
            newIndex,
            maxButton,
            buttonListSetup1,
            buttonListSetup2,
            handleActiveButon,
            hanldeSearchPage,
        }
    }
})
