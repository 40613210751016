
import { PropType, defineComponent, ref, watch } from "vue"
import { Editor } from "@tiptap/vue-3"
import { onMounted } from "vue"

const TYPE_TEXT_LIST = ['h1', 'h2', 'h3', 'h4']

export default defineComponent({
    name: "ComboText",
    props: {
        editor: {
            type: Object as () => Editor,
            required: true
        },
        hiddenStarterKitItems: {
            type: Array as PropType<string[]>,
            default: [],
        }
    },
    setup(props) {
        /** Variables */
        const typeText = ref("paragraph")
        const disabled = ref(false)
        const hidenTypeTextList = ref<string[]>(new Array())

        /** Functions */
        function handleTypeText() {
            if(props.editor.isActive('heading', { level: 1 }))
                typeText.value = 'h1'
            if(props.editor.isActive('heading', { level: 2 }))
                typeText.value = 'h2'
            if(props.editor.isActive('heading', { level: 3 }))
                typeText.value = 'h3'
            if(props.editor.isActive('heading', { level: 4 }))
                typeText.value = 'h4'
            if(props.editor.isActive('paragraph'))
                typeText.value = 'paragraph'
        }

        function handleDisabledItems() {
            hidenTypeTextList.value = new Array()

            TYPE_TEXT_LIST.forEach(itemI => {
                if(itemI === props.hiddenStarterKitItems.find(itemJ => itemI === itemJ))
                    hidenTypeTextList.value.push(itemI)
            })

            disabled.value = !(!!(TYPE_TEXT_LIST.length - hidenTypeTextList.value.length))
        }

        /** Life Cycles */
        watch(() => { props.editor.chain() }, handleTypeText, { deep: true })

        onMounted(() => handleDisabledItems())

        return {
            typeText,
            disabled
        }
    }
})
