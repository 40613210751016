
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus'

export default defineComponent({
  setup() {
    const handleCommand = (command: string | number | object) => {
      ElMessage(`click on item ${command}`)
    }
    return {
      handleCommand
    }
  }
})
