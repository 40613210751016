
import { PropType, defineComponent } from "vue"
import { EditorContent, Editor } from '@tiptap/vue-3'
import { BoldButton, ComboText, ItalicButton, LinkButton, RedoButton, StrikeButton, UnderlineButton, UndoButton } from '@/components/CampTiptap/partials'

export default defineComponent({
    name: "CampTiptapEditor",
    props: {
        editor: {
            type: Object as () => Editor,
            required: true
        },
        hiddenStarterKitItems: {
            type: Array as PropType<string[]>,
            default: [],
        }
    },
    components: {
        EditorContent,
        BoldButton,
        ComboText,
        ItalicButton,
        LinkButton,
        RedoButton,
        StrikeButton,
        UnderlineButton,
        UndoButton
    },
    setup(props) {
        /** Variables */

        /** Functions */

        /** Life Cycles */

        return { }
    }
})
