
import { defineComponent, ref, watch, onUnmounted } from "vue";
import { Modal } from "bootstrap";
import { getFileContentType, campHandleLargeName } from "@/composables/DataValidation";

export default defineComponent({
    name: "CampModalMediaFromURL",
    props: {
    toggle: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      required: true
    },
    contentType: {
        type: String,
        default: ""
    },
    uuid: {
      type: String,
      required: true
    },
    header: {
      type: String,
      default: "Mídia"
    },
    headerMaxSize: {
      type: Number,
      default: 30
    },
    modalFullscreenList: {
        type: Array as () => string[],
        default: []
    },
    showBtnFullscreenList: {
        type: Array as () => string[],
        default: []
    },
    autoHideHeaderList: {
        type: Array as () => string[],
        default: []
    },
    timeAutoHide: {
        type: Number,
        default: 2
    }
  },
  setup(props, { emit }) {
    /** Variables */
    let auxModal
    const srcMedia = ref("")
    const contentTypeMedia = ref("")
    const isYoutube = ref(false)
    const isVideo = ref(false)
    const isImage = ref(false)
    const isPdf = ref(false)
    const unsupportedFile = ref(false)
    const isLoadingMedia = ref(false)
    const isFullscreen = ref(false)
    const showBtnFullscreen = ref(false)
    const cssClassDialog = ref("modal-dialog modal-dialog-centered")
    const cssClassContent = ref("modal-content rounded-3 overflow-hidden")
    const cssClassBody = ref("modal-body d-flex justify-content-center align-items-center p-4")
    const hideHeader = ref(false)
    let timeoutId: number | null = null

    /** Functions */
    const resetState = () => {
        srcMedia.value = ""
        contentTypeMedia.value = ""
        isYoutube.value = false
        isVideo.value = false
        isImage.value = false
        isPdf.value = false
        unsupportedFile.value = false
        isLoadingMedia.value = false
        isFullscreen.value = false
        showBtnFullscreen.value = false
        cssClassDialog.value = "modal-dialog modal-dialog-centered"
        cssClassContent.value = "modal-content rounded-3 overflow-hidden"
        cssClassBody.value = "modal-body d-flex justify-content-center align-items-center p-4"
        hideHeader.value = false
    }

    const startLoader = () => isLoadingMedia.value = true
    
    const stopLoader = () => isLoadingMedia.value = false

    const initValues = () => {
        if(srcMedia.value !== props.src) {
            resetState()
            startLoader()
            srcMedia.value = props.src
            contentTypeMedia.value = props.contentType
        }
    }

    const handleFullscreenMode = (fullscreen = true) => {
        cssClassDialog.value = fullscreen ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-dialog-centered"
        cssClassContent.value = fullscreen ? "modal-content" : "modal-content rounded-3 overflow-hidden"
        cssClassBody.value = fullscreen ?
            "modal-body d-flex justify-content-center align-items-center p-0" :
            "modal-body d-flex justify-content-center align-items-center p-4"
        isFullscreen.value = fullscreen
    }

    const handleContentType = async () => {
        /** Handle Youtube URL */
        if(props.src.includes("youtube"))
            isYoutube.value = true

        if(isYoutube.value && props.modalFullscreenList.includes('youtube'))
            handleFullscreenMode()
        if(isYoutube.value && props.showBtnFullscreenList.includes('youtube'))
            showBtnFullscreen.value = true
        if(isYoutube.value)
            return contentTypeMedia.value = "youtube/url"


        /** Handle Media File URL */
        if(contentTypeMedia.value.length < 1)
            contentTypeMedia.value = await getFileContentType(props.src) || ""

        if(contentTypeMedia.value.startsWith("video/"))
            isVideo.value = true
        else if(contentTypeMedia.value.startsWith("image/"))
            isImage.value = true
        else if(contentTypeMedia.value.startsWith("application/pdf"))
            isPdf.value = true
        else {
            stopLoader()
            return unsupportedFile.value = true
        }

        if(isVideo.value && props.modalFullscreenList.includes('video'))
            handleFullscreenMode()
        if(isImage.value && props.modalFullscreenList.includes('image'))
            handleFullscreenMode()
        if(isPdf.value && props.modalFullscreenList.includes('pdf'))
            handleFullscreenMode()

        if(isVideo.value && props.showBtnFullscreenList.includes('video'))
            showBtnFullscreen.value = true
        if(isImage.value && props.showBtnFullscreenList.includes('image'))
            showBtnFullscreen.value = true
        if(isPdf.value && props.showBtnFullscreenList.includes('pdf'))
            showBtnFullscreen.value = true
    }

    const handleAutoHideHeader = () => {
        hideHeader.value = false

        if(timeoutId)
            clearTimeout(timeoutId)

        timeoutId = window.setTimeout(() => {
            if(isYoutube.value && props.autoHideHeaderList.includes('youtube') && isFullscreen.value)
                hideHeader.value = true
            if(isVideo.value && props.autoHideHeaderList.includes('video') && isFullscreen.value)
                hideHeader.value = true
            if(isImage.value && props.autoHideHeaderList.includes('image') && isFullscreen.value)
                hideHeader.value = true
            if(isPdf && props.autoHideHeaderList.includes('pdf') && isFullscreen.value)
                hideHeader.value = true
        }, props.timeAutoHide * 1000)
    }

    const openModal = async (id: string) => {
        const auxElement = document.querySelector(`#${id}`)
        auxModal = new Modal(auxElement)
        auxModal.show()
        await handleContentType()
        handleAutoHideHeader()
    }

    /** User actions - Life Cycles */
    watch(() => props.toggle, async () => {
        initValues()
        await openModal(`camp-modal-media-from-URL-${props.uuid}`)
    })

    onUnmounted(() => {
        if(timeoutId)
            clearTimeout(timeoutId)
    })

    return {
        contentTypeMedia,
        isLoadingMedia,
        stopLoader,
        srcMedia,
        isYoutube,
        isVideo,
        isImage,
        isPdf,
        cssClassDialog,
        cssClassContent,
        cssClassBody,
        showBtnFullscreen,
        isFullscreen,
        handleFullscreenMode,
        unsupportedFile,
        hideHeader,
        handleAutoHideHeader,
        campHandleLargeName
    }
  }
})
