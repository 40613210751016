import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column px-4 pt-0 pb-1",
  style: {"list-style":"none","padding":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, { trigger: "click" }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header"),
          _createElementVNode("ul", _hoisted_1, [
            _renderSlot(_ctx.$slots, "list")
          ])
        ]),
        _: 3
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "button")
    ]),
    _: 3
  }))
}