
import { defineComponent } from "vue"
import { Editor } from "@tiptap/vue-3"
import { BaseButtonIcons } from "."

export default defineComponent({
    name: "LinkButton",
    components: {
        BaseButtonIcons
    },
    props: {
        editor: {
            type: Object as () => Editor,
            required: true
        }
    },
    setup(props) {
        /** Functions */
        function setLink() {
            const previousUrl = props.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                props.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()
                return
            }

            // update link
            props.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        }

        const handleClickLink = () =>!(props.editor.isActive('link')) ? setLink() : props.editor.chain().focus().unsetLink().run()

        return { handleClickLink }
    }

})
