import { defineStore } from "pinia";
import axios, { AxiosError } from "axios";
import { IUserData } from "./UsersStore";

export const useAuthStore = defineStore("AuthStore", {
  state: () => {
    return {
      user: null as any,
      // userLevel: null as  number | null
    }
  },
  actions: {
    async sendPasswordSms(cpf: string) {
      try {
        const response = await axios.post("/api/send-password-sms/painel", { cpf })
        return response
      } catch (error) {
        throw error
      }
    },
    async sendPasswordEmail(cpf: string) {
      try {
        const response = await axios.post("/api/send-password-email/painel", { cpf })
        return response
      } catch (error) {
        throw error
      }
    },
    async authentication(cpf: string, password: string) {
      try {
        const response = await axios.post("/auth/user", { cpf, password })
        if(response.status === 200) {
          this.user = response.data
        } else {
          this.user = null
        }
        return response
      } catch (error) {
        throw error
      }
    },
    async getUserAuth() {
      try {
        const response = await axios.get("/api/getUserViewAuth")
        if(response.status === 200) {
          this.user = response.data.data
        } else {
          this.user = null
        }
        return response
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response?.status === 401) {
            localStorage.clear()
          }
        }
        throw error
      }
    },
    async logoutUser(reload?: "reload") {
      try {
        const response = await axios.post("/auth/logout/user")
        if(response.status === 204) {
          this.user = null
          localStorage.clear()
          reload == "reload" && location.reload()
        }
        return response
      } catch (error) {
        throw error
      }
    },
  },
  getters: {
    isLoggedIn() {
      const meUser = this.user as any
      return !!meUser
    },
    getUser():any {
      return this.user.user
    },
    getUserLevel(): any {
      return this.user.user.role.level
    },
    getCompany(): any {
      return this.user.company
    },
    getStore(): any {
      return this.user.store
    },
    isSuperUser(): Boolean {
      return this.user.user.role.level <= 100
    },
    isAdmUser(): Boolean {
      return this.user.user.role.level <= 200
    },
    isStorageManagerUser(): Boolean {
      return this.user.user.role.level <= 500
    }
  }
});
